import * as React from 'react'
import Layout from '@components/layout'
import TheEnd from '@components/theend'
import { useTranslation } from 'react-i18next'
import Box from '@common/box'

export default function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <Layout>
      <Box>
        <header>
          <h1>{t('404.title')}</h1>
        </header>
        <section>
          <p><strong>{t('404.www')}</strong> {t('404.p1')}</p>
          <p>{t('404.p2')}</p>
        </section>
      </Box>
      <TheEnd />
    </Layout>
  )
}
